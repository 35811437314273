import React from 'react';
import { Link } from 'gatsby';

interface TagListProps {
  tags: string[]
}

const TagList = (props: TagListProps) =>
  props.tags
    .map((tag) => <Link key={tag} to={`/tag/${tag}`}>{tag}</Link>)
    .reduce((acc, el) => [acc, ', ', el])

export default TagList;
