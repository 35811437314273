import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogPost from '../components/blogPost';

const BlogPostTemplate = ({ data, location }: { data: BlogPostResult; location: any }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const { previous, next } = data;

  return (
    <Layout bottomBio={true}>
      <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
      <BlogPost
        slug={post.fields.slug}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        body={post.html}
        timeToRead={post.timeToRead}
        tags={post.frontmatter.tags}
      />
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <hr />
    </Layout>
  );
};

export default BlogPostTemplate;

interface BlogPostResult {
  site: {
    siteMetadata?: { title: string };
  };
  markdownRemark: {
    id: string;
    excerpt: string;
    html: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      date: string;
      description?: string;
      tags: string[];
    };
    timeToRead: number;
  };
  previous?: BlogLinkResult;
  next?: BlogLinkResult;
}

interface BlogLinkResult {
  fields: { slug: string };
  frontmatter: { title: string };
}

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        tags
      }
      timeToRead
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
