import React from 'react';
import { Link } from 'gatsby';
import Article from './article';
import TagList from './tagList';

const BlogPost = ({
  slug,
  title,
  date,
  body,
  timeToRead,
  tags,
}: {
  slug: string;
  title: string;
  date: string;
  body: string;
  timeToRead: number;
  tags: string[];
}) => {
  return (
    <Article key={slug}>
      <header className="pb-2">
        <h2>
          <Link to={slug} itemProp="url" className="text-info">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small>
          {date} — {timeToRead} {timeToRead == 1 ? `minute` : `minutes`} to read — Tags: <TagList tags={tags} />
        </small>
      </header>
      <section dangerouslySetInnerHTML={{ __html: body }} itemProp="articleBody" />
    </Article>
  );
};

export default BlogPost;
